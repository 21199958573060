"use client"

// Imports - Node
import { useEffect } from "react"
import { stagger, useAnimate } from "framer-motion"

// Imports - Local
import { useLanding } from "@/contexts/Landing"

// Import Types - Node
import type { AnimationSequence } from "framer-motion"

const GREETING: string[] = ["Hello", "नमस्ते", "Hola", "Merhaba", "Привет", "Salve", "Bonjour"]

type Props = {}

function PartialLanding({ }: Props): React.ReactNode {
	const { setActive } = useLanding()
	const [scope, animate] = useAnimate<HTMLElement>()

	useEffect(() => {
		const sequence: AnimationSequence = [
			["#mf-logo", { opacity: 1 }, { ease: "easeIn", duration: 0.3 }],
			[".landing-word", { y: [-50, 0, 50], opacity: [0, 1, 0] }, { ease: [0.35, 0, 0.3, 1], duration: 0.4, delay: stagger(0.2), at: "-0.2" }],
			["#mf-logo", { opacity: 0 }, { ease: "easeOut", duration: 0.5, at: "-0.2" }],
			[scope.current, { opacity: 0, display: "none" }, { ease: "linear", duration: 0.7 }]
		]

		document.body.style.height = "100vh"
		document.body.style.overflow = "hidden"
		animate(sequence).then(() => { document.body.removeAttribute("style") }).finally(() => setActive(false))
	}, [])

	return (
		<section id="landing-animation" ref={scope} className="fixed top-0 left-0 w-screen h-screen overflow-hidden flex justify-center items-center bg-background cursor-progress select-none z-50">
			<div className="absolute top-0 left-0 w-full h-1/2 overflow-hidden flex -z-30">
				<div className="w-full h-full flex flex-col">
					<div className="w-1/3 h-1/3 md:h-full flex items-end translate-y-1">
						<div className="relative w-full h-1 overflow-hidden flex items-center">
							<div className="absolute -left-full w-[35%] h-full bg-gradient-to-r via-secondary from-transparent to-transparent animate-x-slide-right" />
						</div>
					</div>
					<div className="w-2/3 h-2/3 md:h-full flex flex-col self-end">
						<div className="w-full h-2/3">
							<div className="relative w-1 h-full overflow-hidden flex justify-center">
								<div className="absolute -top-full w-full h-[35%] bg-gradient-to-b via-secondary from-transparent to-transparent animate-y-slide-down [animation-delay:0.5s]" />
							</div>
						</div>
						<div className="w-full h-1/3">
							<div className="relative w-full h-1 overflow-hidden flex items-center">
								<div className="absolute -left-full w-[25%] h-full bg-gradient-to-r via-secondary from-transparent to-transparent animate-x-slide-right [animation-delay:1s]" />
							</div>
						</div>
					</div>
				</div>
				<div className="w-full h-full flex flex-col">
					<div className="w-3/4 h-1/3 flex items-end self-end translate-y-1">
						<div className="relative w-full h-1 overflow-hidden flex items-center">
							<div className="absolute -left-full w-[25%] h-full bg-gradient-to-r via-secondary from-transparent to-transparent animate-x-slide-right [animation-delay:0.5s]" />
						</div>
					</div>
					<div className="w-1/4 h-full">
						<div className="w-full h-full flex justify-end">
							<div className="relative w-1 h-full overflow-hidden flex justify-center">
								<div className="absolute -bottom-full w-full h-[35%] bg-gradient-to-b via-secondary from-transparent to-transparent animate-y-slide-up" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="h-80 xl:h-40 flex max-xl:flex-col justify-center items-center shrink-0 max-xl:gap-y-4 bg-background">
				<div className="h-full p-10 xl:p-5 flex justify-center items-center">
					<svg id="mf-logo" style={{ opacity: '0' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550 350" fill="currentColor" className="h-full fill-primary">
						<path d="M150.05,300v50H0c25.92-10.83,48.45-28.18,65.51-50,21.12-27.02,33.89-60.89,34.4-97.72,0-.76,0-1.52,0-2.28s0-1.52,0-2.28c-.29-34.58-7.27-67.57-19.71-97.72h69.73v200h.12Z" />
						<path d="M299.84,300v50s150.05,0,150.05,0c-25.92-10.83-48.45-28.18-65.51-50-21.12-27.02-33.89-60.89-34.4-97.72,0-.76,0-1.52,0-2.28s0-1.52,0-2.28c.29-34.58,7.27-67.57,19.71-97.72h-69.73s0,200,0,200h-.12Z" />
						<path d="M493.2,267.68c17.68,0,26.52-8.84,26.52-26.52,0,17.68,8.84,26.52,26.52,26.52-17.68,0-26.52,8.84-26.52,26.52,0-17.68-8.84-26.52-26.52-26.52Z" />
						<polygon points="550 0 500 49.99 500 50 249.95 50 249.95 250 199.94 250 199.94 50 74.93 50 124.94 0 550 0" />
						<polygon points="499.9 150 449.89 200 399.89 200 399.89 150 499.9 150" />
					</svg>
				</div>
				<div className="relative w-96 h-full flex flex-col justify-center items-center overflow-hidden">
					{GREETING.map((word, index) => (
						<span key={index} style={{ opacity: '0' }} className="landing-word absolute text-4xl xl:text-5xl flex items-center before:mr-4 before:size-2 before:rounded-full before:bg-foreground">
							{word}
						</span>
					))}
				</div>
			</div>
			<div className="absolute bottom-0 left-0 w-full h-1/2 overflow-hidden flex -z-30">
				<div className="w-full h-full flex flex-col">
					<div className="w-2/3 h-full flex flex-col self-end translate-y-1">
						<div className="w-full h-full flex justify-end">
							<div className="relative w-1 h-full overflow-hidden flex justify-center">
								<div className="absolute -top-full w-full h-[25%] bg-gradient-to-b via-secondary from-transparent to-transparent animate-y-slide-down" />
							</div>
						</div>
						<div className="w-full">
							<div className="relative w-full h-1 overflow-hidden flex items-center">
								<div className="absolute -right-full w-[25%] h-full bg-gradient-to-r via-secondary from-transparent to-transparent animate-x-slide-left [animation-delay:0.5s]" />
							</div>
						</div>
					</div>
					<div className="w-1/3 h-full flex justify-end">
						<div className="relative w-1 h-full overflow-hidden flex">
							<div className="absolute -top-full w-full h-[35%] bg-gradient-to-b via-secondary from-transparent to-transparent animate-y-slide-down [animation-delay:1s]" />
						</div>
					</div>
				</div>
				<div className="w-full h-full flex flex-col">
					<div className="w-1/3 h-1/3 flex items-end self-end translate-y-1">
						<div className="relative w-full h-1 overflow-hidden flex items-center">
							<div className="absolute -right-full w-[25%] h-full bg-gradient-to-r via-secondary from-transparent to-transparent animate-x-slide-left" />
						</div>
					</div>
					<div className="w-2/3 h-2/3 flex justify-end">
						<div className="relative w-1 h-full overflow-hidden flex justify-center">
							<div className="absolute -top-full w-full h-[25%] bg-gradient-to-b via-secondary from-transparent to-transparent animate-y-slide-down [animation-delay:0.5s]" />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

// Exports
export default PartialLanding
