"use client"

// Imports - Node
import Link from "next/link"
import { motion } from "framer-motion"
import { useTranslations } from "next-intl"

// Imports - Local
import Tooltip from "@/components/ui/Tooltip"
import { useLanding } from "@/contexts/Landing"
import { Link as NextIntlLink, usePathname } from "@/lib/i18n/routing"

type Props = {}

function PartialFooter({ }: Props): React.ReactNode {
	const { active } = useLanding()
	const t = useTranslations("Components.Footer")
	const pathname = usePathname()

	return (
		<motion.footer initial={{ opacity: 0 }} animate={!active && { opacity: 1 }} transition={{ ease: "linear", duration: 0.3 }} className="relative bottom-0 left-0 w-full md:h-44 max-md:rounded-t-xl max-md:border-t-2 max-md:border-palette-5 flex shrink-0 justify-center max-md:flex-col max-md:bg-background">
			<aside className="max-xl:hidden h-full flex flex-1 flex-col justify-end">
				<div className="w-full h-16 flex justify-center items-center gap-x-2">
					<span className="text-sm text-muted-foreground">
						{t("design")}
					</span>
					<Tooltip top tooltip="MelMenWare LLC.">
						<span className="text-sm text-muted-foreground">
							MMW
						</span>
					</Tooltip>
				</div>
			</aside>
			<div className="w-full md:w-9/12 xl:w-7/12 4xl:w-6/12 h-full flex shrink-0 flex-col">
				<nav className="w-full p-3 rounded-md flex flex-auto max-md:flex-col md:justify-between max-md:items-center max-md:gap-y-4">
					<div className="w-72 h-full flex shrink-0 flex-col gap-y-2">
						<div className="w-full p-1.5 rounded-md border border-palette-2 flex shrink-0 justify-center bg-palette-1 transition-[border-color,_background-color] ease-linear">
							<span className="font-semibold tracking-wide text-palette-foreground-8">
								{t("Columns.social")}
							</span>
						</div>
						<div className="w-full flex flex-auto flex-col justify-center gap-y-1">
							<div className="w-full flex justify-center items-center gap-x-2">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" stroke="none" className="size-5 fill-primary">
									<path strokeLinecap="round" strokeLinejoin="round" d="M12 .3a12 12 0 0 0-3.8 23.38c.6.11.83-.26.83-.57L9 20.87c-3.34.73-4.04-1.41-4.04-1.41-.55-1.4-1.33-1.76-1.33-1.76-1.1-.74.08-.73.08-.73 1.2.09 1.84 1.24 1.84 1.24 1.07 1.83 2.8 1.3 3.49 1 .1-.78.42-1.31.76-1.61-2.67-.3-5.47-1.33-5.47-5.93 0-1.31.47-2.38 1.24-3.22a4.3 4.3 0 0 1 .12-3.18s1-.32 3.3 1.23a11.5 11.5 0 0 1 6 0c2.3-1.55 3.3-1.23 3.3-1.23.66 1.66.25 2.88.12 3.18a4.64 4.64 0 0 1 1.24 3.22c0 4.6-2.81 5.62-5.48 5.92.43.37.81 1.1.81 2.22l-.01 3.3c0 .32.21.69.82.57A12 12 0 0 0 12 .3z" />
									<path strokeLinecap="round" strokeLinejoin="round" d="M4.54 17.53c-.02.06-.12.07-.2.03-.09-.04-.14-.12-.1-.18.02-.06.11-.07.2-.03.08.03.13.12.1.18zm.49.54c-.06.05-.17.03-.24-.06-.08-.08-.1-.2-.04-.25.06-.05.17-.02.25.06.08.08.1.2.03.25zm.47.69c-.07.05-.19 0-.26-.1-.08-.11-.08-.24 0-.29.07-.05.2 0 .27.1s.07.24 0 .29zm.65.67c-.06.07-.2.05-.3-.05-.11-.1-.14-.23-.07-.3.06-.08.2-.06.3.04.11.1.14.23.07.3zm.9.38c-.03.1-.17.14-.3.1-.14-.04-.23-.15-.2-.25.03-.09.16-.13.3-.1.14.05.23.16.2.25zm.98.08c0 .1-.11.18-.26.18-.14 0-.26-.08-.26-.18 0-.1.12-.18.26-.18s.26.08.26.18zm.91-.16c.02.1-.08.2-.22.22-.14.03-.27-.03-.29-.13-.01-.1.09-.2.23-.22.14-.03.27.03.28.13z" />
								</svg>
								<Link href={{ pathname: "https://github.com/Sunbulzade" }} target="_blank" className="group/social relative flex">
									<span className="text-palette-foreground-3">
										Github
									</span>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" strokeWidth={1.25} stroke="currentColor" className="absolute top-0 -right-6 size-5 opacity-0 group-hover/social:opacity-100 stroke-foreground pointer-events-none transition-opacity duration-300 ease-in-out">
										<path strokeLinecap="round" strokeLinejoin="round" d="M7 17L17 7M7 7h10v10" />
									</svg>
								</Link>
							</div>
						</div>
					</div>
					<div className="w-72 h-full flex shrink-0 flex-col gap-y-2">
						<div className="w-full p-1.5 rounded-md border border-palette-2 flex shrink-0 justify-center items-center gap-x-2 bg-palette-1 transition-[border-color,_background-color] ease-linear">
							<span className="font-semibold tracking-wide text-palette-foreground-8">
								{t("Columns.location")}
							</span>
						</div>
						<div className="w-full flex flex-auto flex-col justify-center gap-y-1">
							<div className="w-full flex justify-center items-center gap-x-2">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" stroke="none" className="size-5 fill-primary">
									<path fillRule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
								</svg>
								<p className="text-center text-palette-foreground-3">
									ISPARTA / Merkez - 32200
								</p>
							</div>
						</div>
					</div>
				</nav>
				<div className="group/copyright w-full py-1.5 md:py-3 flex shrink-0 max-md:flex-col justify-center gap-2">
					<div className="flex justify-center">
						<NextIntlLink href={{ pathname: "/privacy-policy" }} className={`underline text-sm  ${pathname === "/privacy-policy" ? "text-palette-foreground-7" : "text-muted-foreground hover:text-palette-foreground-7"} transition-[color] ease-linear`}>
							{t("Links.privacy_policy")}
						</NextIntlLink>
					</div>
					<div className="flex max-md:justify-center items-center gap-x-1">
						<span className="text-sm text-palette-foreground-6">
							{t("copyright")}
						</span>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" strokeWidth={1.25} stroke="currentColor" className="size-4 fill-destructive-foreground stroke-destructive group-hover/copyright:animate-pulse">
							<path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
						</svg>
						<span className="text-sm text-palette-foreground-6">
							Melihhan FAKİR
						</span>
					</div>
				</div>
			</div>
			<aside className="max-xl:hidden h-full flex flex-1 flex-col justify-end">
				<div className="w-full max-md:mb-2 md:h-16 flex justify-center items-center gap-x-2">
					<span className="text-sm text-muted-foreground">
						{t("version")}
					</span>
					<span className="text-sm text-muted-foreground">
						2024
					</span>
				</div>
			</aside>
		</motion.footer>
	)
}

// Exports
export default PartialFooter
