"use client"

// Imports - Node
import { motion } from "framer-motion"
import { useTranslations } from "next-intl"

// Imports - Local
import Separator from "@/components/ui/Separator"
import { Link } from "@/lib/i18n/routing"
import { useLanding } from "@/contexts/Landing"

function LocaleNotFound(): React.ReactNode {
	const t = useTranslations("Pages.NotFound")
	const { active } = useLanding()

	return (
		<motion.div initial={{ opacity: 0 }} animate={!active && { opacity: 1 }} transition={{ ease: "linear", duration: 0.3 }} className="w-full flex flex-1 justify-center items-center">
			<div className="w-11/12 md:w-9/12 xl:w-7/12 4xl:w-6/12 py-12 xl:py-8 flex flex-col">
				<div className="w-full flex justify-center items-center gap-x-4">
					<span className="text-6xl font-light text-primary">
						4
					</span>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 612" fill="currentColor" stroke="none" className="size-20 fill-primary animate-pulse [animation-duration:3s]">
						<path d="M4.283,208.326c-28.015,71.685,84.358,182.589,250.992,247.71c166.634,65.121,324.428,59.801,352.442-11.884 c17.432-44.606-19.496-104.396-90.027-158.893c-0.489,22.675-4.952,44.944-13.309,66.328 c-3.559,9.108-11.697,21.519-30.254,28.923c-10.853,4.33-24.392,6.526-40.242,6.526c-40.758,0-99.372-14.662-156.792-39.22 c-27.484-11.755-52.967-25.125-73.694-38.665c-23.871-15.594-41.229-31.196-51.592-46.373 c-15.399-22.551-13.289-40.954-8.807-52.421c8.368-21.412,20.275-40.884,35.185-57.777 C89.396,144.815,21.715,163.723,4.283,208.326z M546.789,412.194c7.682-5.648,19.708-2.339,26.861,7.39 c7.153,9.729,6.724,22.194-0.958,27.842s-19.709,2.339-26.861-7.39C538.678,430.307,539.107,417.842,546.789,412.194z M248.81,406.547c3.842-9.832,16.98-13.886,29.344-9.054c12.363,4.832,19.271,16.719,15.428,26.552 c-3.842,9.832-16.98,13.887-29.344,9.054C251.875,428.267,244.969,416.379,248.81,406.547z M51.312,246.776 c-11.854,2.301-22.937-3.422-24.754-12.782c-1.817-9.361,6.321-18.813,18.174-21.114c11.854-2.301,22.937,3.422,24.754,12.782 C71.303,235.022,63.167,244.475,51.312,246.776z" />
						<path d="M433.885,363.563c24.904,0,42.999-6.106,48.633-20.52c9.669-24.741,13.162-50.371,11.229-75.19 c-4.919-63.176-45.008-121.096-107.987-145.708c-20.393-7.97-41.378-11.745-62.027-11.744 c-43.118,0.003-84.718,16.481-116.132,45.623c-18.252,16.932-33.069,38.136-42.738,62.878 C143.296,274.09,338.533,363.563,433.885,363.563z" />
					</svg>
					<span className="text-6xl font-light text-primary">
						4
					</span>
				</div>
				<div className="w-full my-8">
					<Separator />
				</div>
				<div className="w-full mt-4 flex flex-col items-center gap-y-8">
					<h5 className="text-lg">
						{t("title")}
					</h5>
					<div className="w-full flex flex-col-reverse items-center gap-y-8">
						<Link href={{ pathname: '/' }} className="peer/link group/link px-6 py-3 rounded-sm border border-action-foreground hover:border-action flex items-center gap-x-2 bg-action transition-[border-color,_background-color] ease-linear">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" strokeWidth={1.5} stroke="currentColor" className="size-5 group-hover/link:-translate-x-1 stroke-foreground transition-[stroke,_transform] ease-linear">
								<path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
							</svg>
							<span>
								{t("Links.home")}
							</span>
						</Link>
						<p className="text-center peer-hover/link:text-palette-foreground-7 transition-[color] ease-linear">
							{t("description")}
						</p>
					</div>
				</div>
			</div>
		</motion.div>
	)
}

// Exports
export default LocaleNotFound
