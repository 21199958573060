import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/partials/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/partials/Grid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/partials/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/partials/Quote.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/contexts/Landing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./ttf/comfortaa-variable.ttf\",\"variable\":\"--font-comfortaa\"}],\"variableName\":\"comfortaa\"}");
