"use client"

// Imports - Node
import { motion } from "framer-motion"
import { useTranslations } from "next-intl"

// Imports - Local
import Tooltip from "@/components/ui/Tooltip"
import { useLanding } from "@/contexts/Landing"

type Props = {}

function PartialQuote({ }: Props): React.ReactNode {
	const { active } = useLanding()
	const t = useTranslations("Components.Quote")

	return (
		<motion.aside initial={{ y: 15, opacity: 0 }} animate={!active && { y: 0, opacity: 1 }} transition={{ ease: "easeIn", duration: 0.5, delay: 0.3 }} className="w-full flex shrink-0 justify-center">
			<div className="w-11/12 md:w-7/12 md:max-w-[75%] pt-1.5 text-center flex shrink-0 justify-center">
				<Tooltip tooltip={t("tooltip")}>
					<p className="text-sm text-palette-foreground-4">
						Her düşen yaprak koltuğunun altında bir iz bırakır.
						<span className="whitespace-nowrap">
							<span className="ml-2">
								-
							</span>
							<span className="underline">
								Hüseyin FAKİR
							</span>
						</span>
					</p>
				</Tooltip>
			</div>
		</motion.aside>
	)
}

// Exports
export default PartialQuote
