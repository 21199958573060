"use client"

// Imports - Node
import { createPortal } from "react-dom"
import { useTranslations } from "next-intl"
import { stagger, useAnimate } from "framer-motion"
import { useEffect, useRef, useState } from "react"

// Imports - Local
import ThemeSwitcher from "@/components/ThemeSwitcher"
import LocaleSwitcher from "@/components/LocaleSwitcher"
import { Link, usePathname } from "@/lib/i18n/routing"

// Import Types - Node
import type { AnimationSequence } from "framer-motion"

// Import Types - Local
import type { Pathnames } from "@/lib/i18n/routing"

type HeaderLinksIntl = "works" | "about" | "contact"

const HEADER_LINKS = ["/works", "/about", "/contact"] satisfies Pathnames[]

type Props = {}

function MobileMenu({ }: Props): React.ReactNode {
	const pathname = usePathname()
	const t_link = useTranslations("Components.Header.Links")
	const t_footer = useTranslations("Components.Footer")
	const menuItemsRef = useRef<HTMLElement | null>(null)
	const [scope, animate] = useAnimate<HTMLElement>()
	const [active, setActive] = useState<boolean>(false)
	const [toggle, setToggle] = useState<boolean>(false)

	function _setToggle(value: boolean) {
		if (value)
			setActive(true)
		setToggle(value)
	}

	function play() {
		const sequence: AnimationSequence = [
			[scope.current, { opacity: [0, 1] }, { ease: "linear", duration: 0.3 }],
			[".mobile-menu-link", { y: [90, 0], opacity: [0, 1] }, { ease: [0.27, 0.43, 0.27, 0.9], duration: 0.5, delay: stagger(0.1) }],
			["#mobile-menu-language", { opacity: [0, 1] }, { ease: "easeIn", duration: 0.3, at: '<' }],
			["#mobile-menu-footer", { opacity: [0, 1] }, { ease: "easeIn", duration: 0.3 }]
		]
		animate(sequence)
	}

	function reverse() {
		const sequence: AnimationSequence = [
			["#mobile-menu-footer", { y: -70, opacity: 0 }, { ease: "easeOut", duration: 0.3 }],
			["#mobile-menu-language", { opacity: 0 }, { ease: "easeOut", duration: 0.3, at: '<' }],
			[".mobile-menu-link", { y: -90, opacity: 0 }, { ease: "easeOut", duration: 0.3, at: '<' }],
			[scope.current, { opacity: 0 }, { ease: "easeOut", duration: 0.5 }]
		]
		animate(sequence).then(() => setActive(false))
	}


	useEffect(() => {
		if (toggle) {
			document.body.style.height = "100vh"
			document.body.style.overflow = "hidden"
			active && play()
		}
		else {
			document.body.removeAttribute("style")
			active && reverse()
		}
	}, [toggle])
	useEffect(() => { menuItemsRef.current = document.getElementById("menu-items") }, [])

	return (
		<div className="xl:hidden w-fit h-fit z-50">
			<button onClick={() => _setToggle(!toggle)} className="p-1 rounded-md">
				<div className="relative size-7 flex flex-col justify-center items-center">
					<div className={`absolute w-full h-0.5 rounded-full ${toggle ? "translate-y-0 rotate-45" : "-translate-y-2"} bg-foreground transition-transform duration-500`} />
					<div className={`absolute w-full h-0.5 rounded-full ${toggle ? "opacity-0" : ''} bg-foreground transition-opacity duration-200 ease-linear`} />
					<div className={`absolute w-full h-0.5 rounded-full ${toggle ? "translate-y-0 -rotate-45" : "translate-y-2"} bg-foreground transition-transform duration-500`} />
				</div>
			</button>
			{menuItemsRef.current !== null && createPortal(active && (
				<section ref={scope} className="xl:hidden fixed top-0 left-0 w-screen h-screen flex justify-center bg-background transition-[background-color] ease-linear z-40">
					<div className="w-11/12 md:w-3/4 h-full pt-36 pb-12 flex flex-col justify-between">
						<div className="w-full flex flex-col gap-y-8">
							<div id="mobile-menu-language" className="w-full flex justify-end items-center gap-x-4">
								<LocaleSwitcher nextLocale="tr" />
								<LocaleSwitcher nextLocale="en" />
							</div>
							<div className="w-full [clip-path:polygon(0%_0%,100%_0%,100%_100%,0%_100%)]">
								<Link href={{ pathname: '/' }} onClick={() => _setToggle(false)} className="mobile-menu-link block w-fit h-fit p-2">
									<div className={`flex items-center gap-x-4 before:block before:size-1.5 before:rounded-full ${'/' === pathname ? "before:bg-primary" : "before:bg-foreground"}`}>
										<span className={`uppercase text-3xl ${'/' === pathname ? "text-primary transition-[color] ease-linear" : ''}`}>
											{t_link("home")}
										</span>
									</div>
								</Link>
							</div>
							{HEADER_LINKS.map((header_link, index) => (
								<div key={index} className="w-full [clip-path:polygon(0%_0%,100%_0%,100%_100%,0%_100%)]">
									<Link href={{ pathname: header_link }} onClick={() => _setToggle(false)} className="mobile-menu-link block w-fit h-fit p-2">
										<div className={`flex items-center gap-x-4 before:block before:size-1.5 before:rounded-full ${pathname.startsWith(header_link) ? "before:bg-primary" : "before:bg-foreground"}`}>
											<span className={`text-3xl ${header_link === pathname ? "text-primary transition-[color] ease-linear" : ''}`}>
												{t_link(header_link.substring(1) as HeaderLinksIntl)}
											</span>
										</div>
									</Link>
								</div>
							))}
						</div>
						<div id="mobile-menu-footer" className="w-full flex flex-col justify-center gap-y-4">
							<div className="w-full h-20 flex justify-center">
								<div className="flex items-center gap-x-4">
									<ThemeSwitcher nextTheme="light" themeColor="bg-[hsl(0,_0%,_96.1%)]" className="outline outline-1 outline-secondary dark:outline-transparent" />
									<ThemeSwitcher nextTheme="dark" themeColor="bg-[hsl(0,_0%,_3.9%)]" className="outline-transparent dark:outline dark:outline-1 dark:outline-secondary" />
								</div>
							</div>
							<div className="w-full flex justify-center">
								<Link href={{ pathname: "/privacy-policy" }} onClick={() => _setToggle(false)} className="underline text-palette-foreground-7">
									{t_footer("Links.privacy_policy")}
								</Link>
							</div>
							<div className="w-full flex flex-col items-center gap-y-4">
								<div className="flex items-center gap-x-1">
									<span className="max-md:text-sm text-palette-foreground-6">
										{t_footer("copyright")}
									</span>
									<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.25} stroke="currentColor" className="size-4 fill-destructive-foreground stroke-destructive group-hover/copyright:animate-pulse">
										<path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
									</svg>
									<span className="max-md:text-sm text-palette-foreground-6">
										Melihhan FAKİR
									</span>
								</div>
								<div className="flex items-center gap-x-1">
									<span className="text-sm text-muted-foreground">
										{t_footer("version")}
									</span>
									<span className="text-sm text-muted-foreground">
										2024
									</span>
								</div>
							</div>
						</div>
					</div>
				</section>
			), menuItemsRef.current)}
		</div>
	)
}

// Exports
export default MobileMenu
