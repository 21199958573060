"use client"

// Imports - Node
import { motion } from "framer-motion"
import { useTranslations } from "next-intl"
import { useEffect, useState } from "react"

// Imports - Local
import MobileMenu from "@/components/MobileMenu"
import SettingsMenu from "@/components/SettingsMenu"
import { useLanding } from "@/contexts/Landing"
import { Link, usePathname } from "@/lib/i18n/routing"

// Import Types - Local
import type { Pathnames } from "@/lib/i18n/routing"

const HEADER_LINKS = ["/works", "/about", "/contact"] satisfies Pathnames[]

type HeaderLinksIntl = "works" | "about" | "contact"

type Props = {}

function PartialHeader({ }: Props): React.ReactNode {
	const { active } = useLanding()
	const [scrolled, setScrolled] = useState<boolean>(false)
	const t = useTranslations("Components.Header.Links")
	const pathname = usePathname()

	useEffect(() => {
		function handleScroll(this: Window, _event: Event) {
			if (this.scrollY > 0) { setScrolled(true) }
			else { setScrolled(false) }
		}

		window.addEventListener("scroll", handleScroll)
		return () => { window.removeEventListener("scroll", handleScroll) }
	}, [])

	return (
		<motion.header initial={{ opacity: 0 }} animate={!active && { opacity: 1 }} transition={{ ease: "linear", duration: 0.3 }} className={`sticky top-0 left-0 w-full h-32 border-b ${scrolled ? "border-palette-4 bg-background" : "border-transparent"} flex shrink-0 justify-center transition-[border-color,_background-color] ease-linear z-30`}>
			<div className="w-11/12 md:w-9/12 xl:w-7/12 4xl:w-6/12 h-full flex shrink-0">
				<nav className="w-full h-full flex flex-auto justify-between items-center">
					<Link href={{ pathname: '/' }} className="group/link p-2 rounded-md border border-primary grow-0 shrink-0 bg-palette-1 transition-[border-color,_background-color] ease-linear">
						<span className={`max-md:hidden text-lg tracking-wider font-bold ${pathname === '/' ? "text-primary" : "group-hover/link:text-primary"} transition-[color] ease-linear`}>
							MELİHHAN FAKİR
						</span>
						<span className={`md:hidden text-lg tracking-wider font-bold ${pathname === '/' ? "text-primary" : "group-hover/link:text-primary"} transition-[color] ease-linear`}>
							MF32
						</span>
					</Link>
					<div className="max-xl:hidden h-full flex items-center gap-x-4">
						{HEADER_LINKS.map((header_link, index) => (
							<Link key={index} href={{ pathname: header_link }} className="group/link p-1 overflow-hidden flex">
								<span className="sr-only">{header_link.substring(1)}</span>
								<div className="relative flex justify-center">
									{t(header_link.substring(1) as HeaderLinksIntl).split('').map((link_character, index) => (
										<div key={index} style={{ transitionDelay: `${20 * index}ms` }} className={`flex flex-col ${pathname.startsWith(header_link) ? "translate-y-8" : "group-hover/link:translate-y-8"} transition-transform duration-200`}>
											<span className="font-semibold">
												{link_character}
											</span>
											<span className="absolute font-semibold text-primary -translate-y-8 transition-[color] ease-linear">
												{link_character}
											</span>
										</div>
									))}
									<div className={`absolute top-full ${pathname.startsWith(header_link) ? "w-full" : "w-0"} h-px translate-y-0.5 bg-primary transition-[width] ease-linear duration-200`} />
								</div>
							</Link>
						))}
					</div>
				</nav>
				<div id="menu-items" className="h-full md:ml-8 flex grow-0 shrink-0 items-center">
					<SettingsMenu />
					<MobileMenu />
				</div>
			</div>
		</motion.header>
	)
}

// Exports
export default PartialHeader
