"use client"

// Imports - Node
import { Fragment, useEffect, useState } from "react"

// Imports - Local
import { useLanding } from "@/contexts/Landing"
import { usePathname } from "@/lib/i18n/routing"

const GAP: number = 600

type Props = {}

function PartialGrid({ }: Props): React.ReactNode {
	const pathname = usePathname()
	const { active } = useLanding()
	const [rows, setRows] = useState<number | boolean>(0)
	const [columns, setColumns] = useState<number | boolean>(0)

	useEffect(() => {
		setRows(Math.ceil(document.body.scrollHeight / GAP))
		setColumns(Math.ceil(document.body.scrollWidth / GAP))
	}, [pathname])

	return (
		<Fragment>
			<section id="grid-columns" className="absolute top-0 left-0 w-full h-full overflow-hidden flex flex-auto justify-evenly select-none pointer-events-none -z-50">
				{!active && Array.from(Array(columns).keys()).map(column => (
					<div key={column} className={`relative w-px h-full overflow-hidden flex items-center bg-palette-2 transition-[background-color] ease-linear`}>
						<div style={{ animationDelay: `${column * 100}ms` }} className={`absolute w-[3px] h-40 bg-gradient-to-b via-palette-shadow from-transparent to-transparent ${column % 2 === 0 ? "-top-full animate-y-slide-blink-down" : "-bottom-full animate-y-slide-blink-up"}`} />
					</div>
				))}
			</section>
			<section id="grid-rows" className="absolute top-0 left-0 w-full h-full overflow-hidden flex flex-col flex-auto justify-evenly select-none pointer-events-none -z-50">
				{!active && Array.from(Array(rows).keys()).map(row => (
					<div key={row} className={`relative w-full h-px overflow-hidden flex items-center bg-palette-2 transition-[background-color] ease-linear`}>
						<div style={{ animationDelay: `${row * 100}ms` }} className={`absolute w-40 h-[3px] bg-gradient-to-r via-palette-shadow from-transparent to-transparent ${row % 2 === 0 ? "-left-full animate-x-slide-blink-right" : "-right-full animate-x-slide-blink-left"}`} />
					</div>
				))}
			</section>
		</Fragment>
	)
}

// Exports
export default PartialGrid
